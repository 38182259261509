<template>
    <div id="app">
        <vue-html2pdf 
        :show-layout="controlValue.showLayout" 
        :float-layout="controlValue.floatLayout" 
        :enable-download="controlValue.enableDownload" 
        :preview-modal="controlValue.previewModal" 
        :filename="controlValue.filename" 
        :paginate-elements-by-height="controlValue.paginateElementsByHeight"
        :pdf-quality="controlValue.pdfQuality" 
        :pdf-format="controlValue.pdfFormat" 
        :pdf-orientation="controlValue.pdfOrientation" 
        :pdf-content-width="controlValue.pdfContentWidth" 
        :manual-pagination="controlValue.manualPagination" 
        :min-height="controlValue.minHeight"
        :show-Zero="controlValue.showZero"
        :html-to-pdf-options="htmlToPdfOptions"
        :quotation-data="quotationData"
        @progress="onProgress($event)" 
        @startPagination="startPagination()" 
        @hasPaginated="hasPaginated()" 
        @beforeDownload="beforeDownload($event)" 
        @hasDownloaded="hasDownloaded($event)" 
        ref="html2Pdf"
        >
            <pdf-content 
            @domRendered="domRendered()" 
            slot="pdf-content"  
            :quotation-data="quotationData"
            />
        </vue-html2pdf>
    
        <section class="title-container">
            <h1 class="title-header">Quotation</h1>
        </section>
    
        <controls-container :progress="progress" @generateReport="downloadPdf()" :quotation-data="quotationData"/>
    </div>
</template>

<script>
import PdfContent from "./PdfContent";
// import VueHtml2pdf from "@/components/VueHtml2pdf";
import ControlsContainer from "./ControlsContainer";
import VueHtml2pdf from 'vue-html2pdf'
// import VueHtml2pdf from "vue-html2pdf-test";
import { mapFields } from "vuex-map-fields";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import quotationStoreModule from "../quotationStoreModule";

export default {
    name: "app",

    data() {
        return {
            contentRendered: false,
            progress: 0,
            generatingPdf: false,
            pdfDownloaded: false,
        };
    },
    setup() {
        const quotationData = ref({})

        const INVOICE_STORE_MODULE_NAME = "quotation";

        if (!store.hasModule(INVOICE_STORE_MODULE_NAME))
            store.registerModule(INVOICE_STORE_MODULE_NAME, quotationStoreModule);

        // onUnmounted(() => {
        //     if (store.hasModule(INVOICE_STORE_MODULE_NAME))
        //         store.unregisterModule(INVOICE_STORE_MODULE_NAME);
        // });

        const refetchData = () => {
            store.dispatch(INVOICE_STORE_MODULE_NAME + "/fetchQuotation", { id: router.currentRoute.params.id })
                .then((response) => {
                    quotationData.value = response.data.quotation;
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        quotationData.value = undefined;
                    }
                });
        }

        refetchData()

        return {
            quotationData,
            refetchData,
        }


    },

    computed: {
        ...mapFields(["controlValue"]),

        htmlToPdfOptions() {
            return {
                margin: 0,
                filename: this.controlValue.filename,
                image: {
                    type: "jpeg",
                    quality: 0.98,
                },
                enableLinks: true,
                html2canvas: {
                    scale: 5,
                    quality: 4,
                    //logging:true,
                    letterRendering: true,
                    useCORS: true,
                },
                jsPDF: {
                    unit: "in",
                    format: this.controlValue.pdfFormat,
                    orientation: this.controlValue.pdfOrientation,
                },
            };
        },
    },

    methods: {
        async downloadPdf() {
            if (!(await this.validateControlValue())) return;

            this.$refs.html2Pdf.generatePdf();
        },

        validateControlValue() {
            if (this.controlValue.pdfQuality > 4) {
                alert("pdf-quality value should only be 0 - 2");
                this.controlValue.pdfQuality = 4;

                return false;
            }

            if (!this.controlValue.paginateElementsByHeight) {
                alert("paginate-elements-by-height value cannot be empty");
                this.controlValue.paginateElementsByHeight = 1400;

                return false;
            }

            const paperSizes = [
                "a0",
                "a1",
                "a2",
                "a3",
                "a4",
                "letter",
                "legal",
                "a5",
                "a6",
                "a7",
                "a8",
                "a9",
                "a10",
            ];

            if (!paperSizes.includes(this.controlValue.pdfFormat)) {
                alert(`pdf-format value should only be ${paperSizes}`);
                this.controlValue.pdfFormat = "a4";

                return false;
            }

            if (!this.controlValue.pdfOrientation) {
                alert("pdf-orientation value cannot be empty");
                this.controlValue.pdfOrientation = "portrait";

                return false;
            }

            if (!this.controlValue.pdfContentWidth) {
                alert("pdf-content-width value cannot be empty");
                this.controlValue.pdfContentWidth = "800px";

                return false;
            }

            return true;
        },

        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },

        startPagination() {
            console.log(`PDF has started pagination`)
        },

        hasPaginated() {
            console.log(`PDF has been paginated`)
        },

        async beforeDownload({ html2pdf, options, pdfContent }) {
            console.log(`On Before PDF Generation`)
            // await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
            // 	const totalPages = pdf.internal.getNumberOfPages()
            // 	for (let i = 1; i <= totalPages; i++) {
            // 		pdf.setPage(i)
            // 		pdf.setFontSize(10)
            // 		pdf.setTextColor(150)
            // 		pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
            // 	} 
            // }).save()
        },

        hasDownloaded(blobPdf) {
            console.log(`PDF has downloaded yehey`)
            this.pdfDownloaded = true
            console.log(blobPdf)
        },

        domRendered() {
            this.contentRendered = true;
        },

        onBlobGenerate(blob) {
            console.log(blob);
        },
    },

    components: {
        VueHtml2pdf,
        PdfContent,
        ControlsContainer,
    },
};
</script>

<style lang="scss" scoped>
@keyframes animate-fade-in-top {
    from {
        transform: translateY(-50px);
        opacity: 0;
    }
    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

@mixin fadeintop($duration, $delay) {
    animation-name: animate-fade-in-top;
    animation-duration: $duration;
    animation-delay: $delay;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
}

html,
body {
    width: 100%;
    padding: 0;
    margin: 0;
}

#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: black;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    //background: #141e30;
    /* fallback for old browsers */
    //background: -webkit-linear-gradient( to bottom, #243b55, #141e30);
    /* Chrome 10-25, Safari 5.1-6 */
    //background: linear-gradient( to bottom, #243b55, #141e30);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    flex-direction: column;
    overflow: hidden;
    .title-container {
        min-height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }
    .title-header {
        color: #fff;
        padding: 25px 50px;
        border: 5px solid #fff;
        border-radius: 5px;
        opacity: 0; //@include fadeintop(0.2s, 0);
    }
}

</style>
